body {
    font-family: 'Roboto Slab', serif;
    text-align: center;
}

.section-1 {
    background-color: #E6E6ED;
    color: #3C4052;
    padding-bottom: 1.5rem;
    display: grid;
    grid-template-columns: 1fr;
}

.about-me {
    font-size: 4rem;
    letter-spacing: 0.5rem;
}

.content-wrapper {
    text-align: left;
    align-items: center;
    display: grid;
    place-items: center;
}

.about-me h1 {
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-size: 3rem;
    opacity: 0;
    animation: fadeIn 3s ease-in-out forwards; 
}

.about-me p{
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    text-align: justify;
    animation: fadeIn 1s ease-in-out forwards; 
    margin: 0 auto;
    width: 60%;
}

.picture-me img {
    border-radius: 5rem;
    animation: fadeIn 1s ease-in-out forwards;
    width: 24rem;
    height: 26rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.section-2 {
    background-color: #E6E6ED;
    color: #3C4052;
    letter-spacing: 0.1rem;
}

.proyectos {
    padding-top: 1rem;
}
.proyectos h2{
    font-size: 2.5rem;
}

footer {
    font-size: 0.8rem;
    text-align: center;
    color: #E6E6ED;
    background-color: #3C4052;
    font-weight: bold;
    padding: 0.5rem;
}


@media (max-width: 800px) {
    .cajablanca {
        grid-template-columns: 1fr;
    }

    .soy, .veo {
        padding: 1rem; 
        font-size: 1.2rem;
    }

    .soy h1 {
        padding-top: 2rem;
    }

    .about-me h1 {
        font-size: 2.6rem;
    }

    .proyectos h2{
        font-size: 2rem;
    }

    .proyectos {
        padding-top: 1rem; 
    }

    footer {
        font-size: 0.8rem; 
    }

    .about-me p{
        width: 90%;
        font-size: 1.2rem;
    }
}