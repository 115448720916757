.carousel-container {
    position: relative;
    width: 100%;
    max-width: 70rem; 
    height: 40rem;
    overflow: hidden;
    margin: 0 auto;
    border-radius: 1rem;
}

.carousel-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
}

.carousel-item {
    flex: 0 0 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-image {
    flex: 1;
    width: 50%;
    height: 50%;
    background-position: center;
    background-size: cover;
    margin-top: 1rem;
    margin-bottom: 0;
    border-radius: 1rem;
    position: absolute;
}

.carousel-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;
    padding: 2.5rem;
    position: absolute;
    bottom: 0; 
    width: 80%;
}

.carousel-buttons {
    display: flex; 
    gap: 2rem;
}

.carousel-prev, .carousel-next {
    font-size: 1.5rem;
    padding: 1rem 2rem;
    background: none;
    border: none;
    color: #202229;
    cursor: pointer;
    transition: opacity 0.3s;
}

.carousel-live, .carousel-code {
    color: #3C4052;
    text-align: center;
    border-radius: 1rem;
    transition: background-color 0.3s, color 0.3s;
}

.carousel-live:hover, .carousel-code:hover {
    background-color: #808085;
    color: #202229;
    border-radius: 1rem;
}

.carousel-navigation {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between; 
    padding: 0 2rem;
}

.carousel-dots {
    position: absolute;
    bottom: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
}

.carousel-dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background: #808085;
    margin: 0 1.5rem;
    cursor: pointer;
}

.carousel-dot.active {
    background: #202229;
}

@media (max-width: 768px) {
    .carousel-content {
        padding: 4rem; 
    }

    .carousel-image {
        width: 97%;
        max-height: 40%;
    }

    .carousel-content {
        padding: 5rem;
    }

    .carousel-content h3 {
        text-align: center;
    }

    .carousel-content p {
        font-size: 0.8rem; 
    }

    .carousel-prev{
        margin: 0rem;
    }

    .carousel-next {
        margin: 4rem;
    }

    .carousel-dots {
        bottom: 1rem;
    }

    .carousel-live, .carousel-code {
        font-size: 0.8rem; 
    }

    .carousel-wrapper {
        flex-wrap: nowrap;
    }
}
